<template>
  <div>
    <div class="content-header">
      <div class="title-1">
        <router-link to="/"
          ><img src="../assets/back_arrow.svg" /><span style="color: #44D62C;"
            >Home</span
          ></router-link
        >
      </div>
    </div>

    <div v-if="errorMessage" class="error-message">
      <p>{{ errorMessage }}</p>
    </div>

    <!-- <div class="single-games">
      <div class="avialable-c">
        <h3>Available Coin</h3>
        <h5>
          <img src="../assets/gold-coins-icon.svg" />
          <span>{{ totalCoin }}</span>
        </h5>
      </div>
    </div> -->
    <div class="packs-sec-1" v-if="packages.length">
      <img src="images/xoshlogo.png" />
      <div class="pack-title">Choose Your Plan</div>

      <div class="pack-details-1">
        <div class="pack-2" v-for="packages in packages" :key="packages.id">
          <button class="pack-button" @click="addsubscription(packages.id)">
            <h1 class="color-01">{{ packages.name }}</h1>
            <h4>{{ packages.price }} BDT <span>(VAT+SD+SC)</span></h4>

            <div class="not-active-2" v-if="packages.status === 'pending'">
              <h3>Coming Soon</h3>
            </div>
          </button>
        </div>
        <p class="renew-text">* Subscription wiil be automatically renewed</p>
      </div>
    </div>
    <footer>
      <div class="main-footer">
        <div class="social-icon"></div>
      </div>
    </footer>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SubscriptionPlan",
  data: () => {
    return {
      packages: [],
      phone: localStorage.getItem("phone"),
      host: null,
      errorMessage: ""
    };
  },
  created() {
    this.host = this.$root.url();
    if (!localStorage.getItem("phone")) {
      this.$router.push({ name: "Registration" });
    }
    const path = "packageList";
    const url = `${this.host}/${path}?phone=${localStorage.getItem("phone")}`;
    axios
      .get(url)
      .then(response => {
        if (response.data.success === true) {
          this.packages = response.data.packages;
        } else {
          if (response.data.code === 3004) {
            localStorage.clear();
            this.$router.push({ name: "Registration" });
          }
          this.packages = [];
          this.errorMessage = response.data.message || "An error occurred";
        }
      })
      .catch(error => {
        console.log(error);
        this.errorMessage = "Network error. Please try again later.";
      });
  },
  methods: {
    buyCoin: function() {
      // console.log(localStorage.getItem("phone")); //do some task for coin purchase
    },
    addsubscription: function(packageId) {
      this.host = this.$root.url();
      const path = "gpCharge";
      const url = `${this.host}/${path}?msisdn=${localStorage.getItem(
        "phone"
      )}&packageId=${packageId}`;
      axios
        .get(url)
        .then(response => {
          if (response.data.status_code === 200) {
            window.location.href = response.data.redirect_url;
          } else {
            this.errorMessage = response.data.message || "An error occurred";
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<style scoped>
.error-message p {
  text-align: center;
  padding: 20px;
  font-size: 20px;
  color: red;
}
</style>
