<template>
  <div class="registration-container">
    <div class="otp-register ">
      <form
        action="#"
        @submit.prevent="registration"
        id="reglog"
        class="otp-form"
      >
        <div id="errorMessage" style="color:red;">&nbsp;</div>
        <label>Select Your Operator</label>

        <ul class="operator-wrapper">
          <li
            v-for="data in paymentList"
            :key="data.value"
            class="operator-btn"
            :class="{ active: operator == data.value }"
            @click="selectOperator(data.value)"
          >
            {{ data.name }}
          </li>
        </ul>
        <label>Enter Your Phone Number</label>
        <div class="operator-number">
          <span>(BD) +88</span>
          <input
            type="number"
            class=""
            v-model="regristrationPhone"
            placeholder="Phone Number"
          />
        </div>
        <button
          type="button"
          class="submit-btn1"
          id="userRegristration"
          @click="registration"
        >
          SUBMIT
        </button>
      </form>
      <div class="no-internet" style="display: none">
        <div class="forgot-pass-1">
          <div class="otp-title">
            <h2>OTP</h2>
            <h3>
              Enter your four digit code that we have send to your phone number
            </h3>
            <h3>(+88{{ regristrationPhone }})</h3>
          </div>
          <div id="errorMessage2" style="color:red;">&nbsp;</div>
          <form action="#">
            <div class="content-forgot">
              <div id="otp-fields">
                <input
                  v-for="(digit, index) in otp"
                  :key="index"
                  type="tel"
                  maxlength="1"
                  v-model="otp[index]"
                  @input="onInput(index)"
                  @keydown="onKeyDown(index, $event)"
                  @focus="focusInput(index)"
                  :ref="`otpInput${index}`"
                  class="otp-field"
                />
              </div>
            </div>
            <div class="otp-submit-btn">
              <button
                type="button"
                class="resend-btn"
                @click="passwordRecovery"
                id="resendOtp"
              >
                Resend Code
              </button>
              <button
                type="button"
                class="submit-btn1"
                @click="otpCheck"
                id="otp"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <footer>
      <div class="main-footer">
        <div class="social-icon"></div>
      </div>
    </footer>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Regristration",
  data: () => {
    return {
      phone: localStorage.getItem("phone"),
      operator: "",
      message: "",
      regristrationPhone: "",
      isDisabled: 0,
      otp: ["", "", "", ""],
      paymentList: [],
      host: null
    };
  },
  created() {
    this.host = this.$root.url();
    if (localStorage.getItem("phone")) {
      this.$router.push({ name: "Home" });
    }
    const path = "operatorList";
    const url = `${this.host}/${path}`;
    axios
      .get(url)
      .then(response => {
        if (response.data.success === true) {
          this.paymentList = response.data.paymentList;
        }
      })
      .catch(error => {
        console.log(error);
      });
  },
  methods: {
    selectOperator(value) {
      this.operator = value; // Update the selected operator
    },
    onInput(index) {
      if (index < this.otp.length - 1 && this.otp[index].length === 1) {
        // Move focus to the next field if the current one is filled
        this.$refs[`otpInput${index + 1}`][0].focus();
      }
    },
    onKeyDown(index, event) {
      if (event.key === "Backspace" && index > 0 && this.otp[index] === "") {
        // Move focus to the previous field if backspace is pressed and the field is empty
        this.$refs[`otpInput${index - 1}`][0].focus();
      }
    },
    focusInput(index) {
      if (this.$refs[`otpInput${index}`]) {
        this.$refs[`otpInput${index}`][0].focus();
      }
    },
    registration: function() {
      $("#userRegristration").hide();
      let phone = this.regristrationPhone;
      let operator = this.operator;
      if (phone !== "" && operator !== "") {
        $("#errorMessage").html("<p>validation successful</p>");
        const path = "userRegistration";
        const url = `${this.host}/${path}?phone=${phone}&payment=${operator}`;
        axios
          .get(url)
          .then(function(response) {
            if (response.data.success === true) {
              $("#errorMessage").html(response.data.messages);
              localStorage.setItem("registerPhone", response.data.phone);
              localStorage.setItem("payment", response.data.payment);
              $("#userRegristration").show();
              $("#reglog").hide();
              $(".no-internet").show();
              return true;
            } else {
              $("#errorMessage").html(response.data.messages);
              $("#userRegristration").show();
              return true;
            }
          })
          .catch(() => {
            $("#errorMessage").html(
              "<p>something goes to wrong,please try again later</p>"
            );
            $("#userRegristration").show();
            return false;
          });
      } else {
        $("#errorMessage").html("<p>please fill up all fields</p>");
        $("#userRegristration").show();
        return false;
      }
    },
    otpCheck: function() {
      let vm = this;
      $("#otp").hide();
      let otpdigits = this.otp;
      let otp = otpdigits.join("");
      let phone = localStorage.getItem("registerPhone");
      let payment = localStorage.getItem("payment");
      if (otp !== "" && phone !== "" && payment !== "") {
        $("#errorMessage2").html("<p>validation successful</p>");
        const path = "otp";
        const url = `${this.host}/${path}?phone=${phone}&otp=${otp}&payment=${payment}`;
        axios
          .get(url)
          .then(function(response) {
            if (response.data.success === true) {
              $("#otp").show();
              localStorage.clear();
              $("#errorMessage2").html(response.data.messages);
              $("#reglog").show();
              $(".no-internet").hide();
              localStorage.setItem("phone", response.data.phone);
              // localStorage.setItem("token", response.data.token);
              if (response.data.login === true) {
                vm.$router.push({ name: "Home" });
              } else {
                vm.$router.push({ name: "Profile" });
              }
            } else {
              $("#otp").show();
              $("#errorMessage2").html(response.data.messages);
              return true;
            }
          })
          .catch(() => {
            $("#otp").show();
            $("#errorMessage2").html(
              "<p>something goes to wrong,please try again later</p>"
            );
            return false;
          });
      } else {
        $("#otp").show();
        $("#errorMessage2").html("<p>please fill up OTP field</p>");
        return false;
      }
    },
    passwordRecovery: function() {
      $("#resendOtp").hide();
      let phone = localStorage.getItem("registerPhone");
      console.log("phone :", phone);
      if (phone !== "") {
        $("#errorMessage2").html("<p>&nbsp;</p>");
        const path = "forgotPassword";
        const type = "registration";
        const url = `${this.host}/${path}?phone=${phone}&type=${type}`;
        axios
          .get(url)
          .then(function(response) {
            if (response.data.success === true) {
              $("#resendOtp").show();
              $("#errorMessage2").html("successfully send otp");
              return true;
            } else {
              $("#resendOtp").show();
              $("#errorMessage2").html(response.data.messages);
              return true;
            }
          })
          .catch(() => {
            $("#resendOtp").show();
            $("#errorMessage2").html(
              "<p>something goes to wrong,please try again later</p>"
            );
            return false;
          });
      } else {
        $("#resendOtp").show();
        $("#errorMessage2").html("<p>please fill up phone number field</p>");
        return false;
      }
    }
  }
};
</script>

<style scoped></style>
