<template>
  <div>
    <div class="content-header">
      <div class="title-1">
        <router-link to="/"
          ><img src="../assets/back_arrow.svg" /><span style="color: #44D62C;"
            >Home</span
          ></router-link
        >
      </div>
    </div>

    <div class="single-games">
      <div id="errorMessage" style="color:red;display: none;">&nbsp;</div>
      <div v-if="successMessage" class="success-message">
        <p>{{ successMessage }}</p>
      </div>
      <div class="detail-sec-1" id="viewProfile">
        <div class="detail-sec-wrapper">
          <h2>Profile Details</h2>
          <button type="button" class="" @click="editProfile">
            Edit Profile
          </button>
        </div>

        <div class="detail-2">
          <div class="user-name">
            <ul>
              <li class="small-user">User Name</li>
              <li class="big-user-title">{{ userDetails.name }}</li>
            </ul>
          </div>
          <div class="user-name">
            <ul>
              <li class="small-user">Phone</li>
              <li class="big-user-title ">+{{ userDetails.phone }}</li>
            </ul>
          </div>
          <div class="user-name">
            <ul>
              <li class="small-user">Gender</li>
              <li class="big-user-title">{{ userDetails.gender }}</li>
            </ul>
          </div>
          <div class="user-name">
            <ul>
              <li class="small-user">Birth Date</li>
              <li class="big-user-title ">
                {{ userDetails.birth_date ? userDetails.birth_date : "--" }}
              </li>
            </ul>
          </div>
          <div class="user-name">
            <ul>
              <li class="small-user">Email</li>
              <li class="big-user-title ">{{ userDetails.email }}</li>
            </ul>
          </div>
          <div class="user-name">
            <ul>
              <li class="small-user">Default Payment</li>
              <li class="big-user-title">
                <form class="payment-select">
                  <select v-model="default_payment" id="operator-select">
                    <option
                      v-for="data in paymentList"
                      :key="data.name"
                      :value="data.value"
                    >
                      {{ data.name }}
                    </option>
                  </select>
                </form>
              </li>
            </ul>
          </div>
          <div class="user-name" id="errorBlock" style="display: none;">
            <ul>
              <li class="small-user" id="errorMsg" style="color:red;">
                &nbsp;
              </li>
            </ul>
          </div>
          <div class="user-name" v-if="default_payment !== 2">
            <ul>
              <li class="small-user">Deactivate Profile</li>
              <li
                class="big-user-title deactivateButton"
                @click="deactivationPopUp"
              >
                Deactivate
              </li>
            </ul>
          </div>
          <div>
            <div
              @click="showModal('subscriptionModal')"
              class="detail-unsubscription-button"
              v-if="isSubscribe"
              :class="{ disabled: isUnsubscribeDisabled }"
              :disabled="isUnsubscribeDisabled"
            >
              UNSUBSCRIBE
            </div>

            <router-link
              :to="{ name: 'SubscriptionPlan' }"
              class="detail-subscription-button"
              v-else
              >GET SUBSCRIPTION</router-link
            >
          </div>
        </div>
      </div>
      <div id="editProfile" style="display: none;">
        <input
          type="text"
          class="form-control"
          v-model="userDetails.name"
          placeholder="Name"
        />
        <input
          type="email"
          class="form-control"
          v-model="userDetails.email"
          placeholder="email"
        />
        <select v-model="userDetails.default_payment">
          <option disabled value="">Please select operator</option>
          <option
            v-for="data in paymentList"
            :key="data.name"
            :value="data.value"
          >
            {{ data.name }}
          </option>
        </select>
        <select v-model="userDetails.gender">
          <option disabled value="">Please select gender</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
        </select>
        <input
          type="text"
          class="form-control"
          :value="userDetails.birth_date"
          id="datepicker"
          placeholder="Birth Date"
        />
        <button
          type="button"
          class="submit-btn1"
          id="editUserInfo"
          @click="editUserInfo"
        >
          DONE
        </button>
      </div>
    </div>
    <footer>
      <div class="main-footer">
        <div class="social-icon"></div>
      </div>
    </footer>
    <div class="modal-background" id="loginError">
      <div class="modal-content pop-02">
        <span class="close" @click="hideModal('loginError')"></span>
        <h4>Are you sure to deactivate account ?</h4>
        <button
          type="submit"
          class="submit-btn5"
          @click="
            deactivateProfile(userDetails.phone, userDetails.default_payment)
          "
        >
          Okay
        </button>
        <button
          type="submit"
          class="submit-btn6"
          @click="hideModal('loginError')"
        >
          Cancel
        </button>
      </div>
    </div>
    <div class="modal-background" id="subscriptionModal">
      <div class="modal-content pop-02">
        <div v-if="errorMessage" class="error-message">
          <p>{{ errorMessage }}</p>
        </div>
        <span class="close" @click="hideModal('subscriptionModal')"></span>
        <h4>Are you sure to deactivate subscription ?</h4>
        <div class="subscriptionBtnGrp">
          <button
            type="submit"
            class="submit-btn5"
            @click="removeSubscription()"
          >
            Okay
          </button>
          <button
            type="submit"
            class="submit-btn6"
            @click="hideModal('subscriptionModal')"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Profile",
  data: () => {
    return {
      userDetails: [],
      host: null,
      default_payment: 0,
      paymentList: [],
      msisdn: null,
      disabled: true,
      formShow: true,
      hideShow: true,
      message: null,
      isSubscribe: false,
      isActiveSubscription: false,
      errorMessage: "",
      successMessage: ""
    };
  },
  created() {
    this.host = this.$root.url();
    $(function() {
      $("#datepicker").datepicker({
        dateFormat: "dd/mm/yy",
        changeMonth: true,
        changeYear: true,
        yearRange: "-90:+0"
      });
    });
    if (!localStorage.getItem("phone")) {
      this.$router.push({ name: "Home" });
    }
    const path = "userDetail";
    const url = `${this.host}/${path}?phone=${localStorage.getItem("phone")}`;
    axios
      .get(url)
      .then(response => {
        if (response.data.success === true) {
          this.userDetails = response.data.userInfo;
          this.default_payment = parseInt(
            response.data.userInfo.default_payment
          );
          this.paymentList = response.data.paymentList;
        } else {
          if (response.data.code === 3004) {
            localStorage.clear();
            this.$router.push({ name: "Registration" });
          }
        }
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      });
    this.activeSubscriptionStatus();
  },
  methods: {
    async deactivateProfile(phone = null, operator = 0) {
      try {
        $(".deactivateButton").hide();
        let curTime = Date.now();
        let randomNumber = Math.floor(100000000 + Math.random() * 900000000);
        let individualTransactionId = curTime + phone + randomNumber;
        const path = "deactivateProfile";
        const url = `${this.host}/${path}?msisdn=${phone}&operator=${operator}&transactionId=${individualTransactionId}`;
        let response = await axios.get(url);
        let { success, message, code } = response.data;
        if (success) {
          if (operator !== 2) {
            localStorage.clear();
          }
          this.$router.push({ name: "Home" });
          return true;
        } else {
          if (code === 3004) {
            localStorage.clear();
            this.$router.push({ name: "Registration" });
            return true;
          }
          $(".deactivateButton").show();
          this.hideModal("loginError");
          $("#errorBlock").show();
          $("#errorMsg").text(message);
        }
      } catch (e) {
        $(".deactivateButton").show();
        this.hideModal("loginError");
        $("#errorBlock").show();
        $("#errorMsg").text("Something wrong, please try again later");
      }
    },
    editProfile() {
      $("#viewProfile").hide();
      $(".detail-section-private").hide();
      $("#editProfile").show();
    },
    async editUserInfo() {
      try {
        $("#editUserInfo").hide();
        let name = this.userDetails.name;
        let email = this.userDetails.email;
        let payment = this.userDetails.default_payment;
        let gender = this.userDetails.gender;
        let birthDate = $("#datepicker").val();
        const path = "editUserInfo";
        const url = `${
          this.host
        }/${path}?name=${name}&phone=${localStorage.getItem(
          "phone"
        )}&email=${email}&payment=${payment}&gender=${gender}&birthDate=${birthDate}`;
        let response = await axios.get(url);
        let success = response.data.success;
        if (success) {
          this.userDetails = response.data.userInfo;
          this.default_payment = response.data.userInfo.default_payment;
          this.totalCoin = response.data.totalCoin;
          $("#viewProfile").show();
          $("#editProfile").hide();
          $(".detail-section-private").show();
          $("#editUserInfo").show();
          $("#errorMessage")
            .show()
            .html("<b>successfully edited user profile</b>");
        } else {
          if (response.data.code === 3004) {
            localStorage.clear();
            this.$router.push({ name: "Registration" });
          }
          $("#editUserInfo").show();
          $("#errorMessage")
            .show()
            .html(`<b>${response.data.message}</b>`);
        }
      } catch (e) {
        $("#editUserInfo").show();
        $("#errorMessage")
          .show()
          .html("<b>Something wrong, please try again later</b>");
      }
    },
    async gift() {
      try {
        this.hideShow = false;
        const msisdn = this.msisdn;
        const coin = this.giftAmount;
        const userPhoneNumber = localStorage.getItem("phone");
        const path = "coinGift";
        const url = `${this.host}/${path}?coin=${coin}&msisdn=${msisdn}&userPhoneNumber=${userPhoneNumber}`;
        let response = await axios.get(url);
        if (response.data.success) {
          this.formShow = false;
          this.message = response.data.message;
        } else {
          this.hideShow = true;
          this.message = response.data.message;
        }
      } catch (e) {
        console.log(e);
        this.hideShow = true;
        this.message = "Something wrong, Please try again later";
      }
    },
    showModal(id) {
      $("#" + id).fadeIn(200);
      $("body").toggleClass("no-scroll");
    },
    hideModal(id) {
      $("#" + id).fadeOut(400);
      $("body").toggleClass("no-scroll");
    },
    deactivationPopUp() {
      this.showModal("loginError");
    },
    subscriptionPopUp() {
      this.showModal("subscriptionModal");
    },
    activeSubscriptionStatus: function() {
      this.host = this.$root.url();
      const path = "activesubscription";
      const url = `${this.host}/${path}?msisdn=${localStorage.getItem(
        "phone"
      )}`;
      axios
        .get(url)
        .then(response => {
          const { status, next_renew_date } = response.data;
          const currentDate = new Date();
          const nextRenewDate = new Date(next_renew_date);

          if (status === "Registered") {
            this.isSubscribe = true;
            this.isUnsubscribeDisabled = false;
          } else if (status === "DeRegistered" && nextRenewDate > currentDate) {
            this.isSubscribe = true;
            this.isUnsubscribeDisabled = true;
          } else {
            this.isSubscribe = false;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    removeSubscription: function() {
      this.host = this.$root.url();
      const path = "gpUnsubscribe";
      const url = `${this.host}/${path}?msisdn=${localStorage.getItem(
        "phone"
      )}`;
      axios
        .get(url)
        .then(response => {
          if (response.data.status_code === 200) {
            this.isActiveSubscription = true;
            this.$router.push({ name: "Profile" });
            this.successMessage = response.data.message;
          } else {
            this.errorMessage = response.data.message || "An error occurred";
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  watch: {
    giftAmount: function(val) {
      if (val !== 0 && this.msisdn !== null) {
        this.message =
          val <= 0 ? "Credit amount must be positive number" : null;
        if (this.msisdn.length >= 11 && this.msisdn.length <= 14) {
          this.disabled = false;
        } else {
          this.disabled = true;
          this.message = "Mobile number length must be between 11 to 14";
        }
      }
    },
    msisdn: function(val) {
      if (this.giftAmount !== 0 && val !== null) {
        this.message =
          this.giftAmount <= 0 ? "Credit amount must be positive number" : null;
        if (val.length >= 11 && val.length <= 14) {
          this.disabled = false;
        } else {
          this.disabled = true;
          this.message = "Mobile number length must be between 11 to 14";
        }
      }
    }
  }
};
</script>

<style scoped>
.subscriptionBtnGrp {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.error-message p {
  text-align: center;
  padding: 20px;
  font-size: 20px;
  color: red;
}

.success-message p {
  text-align: center;
  padding: 20px;
  font-size: 20px;
  color: green;
}

.detail-unsubscription-button.disabled {
  pointer-events: none;
  background-color: #ccc;
  color: #666;
}
</style>
