<template>
  <div class="header" v-if="!$root.mainMenuHide">
    <div class="head-nav">
      <div class="menu-icons">
        <div class="menu-bar" onclick="openNav()">
          <span> </span>
          <span> </span>
          <span> </span>
        </div>
        <div class="mobile-menu-main" id="mySidenav">
          <div class="close-btn" onclick="closeNav()"></div>
          <div class="mob-menu-items">
            <ul>
              <li>
                <router-link to="/" onclick="closeNav()">
                  <img src="../assets/home.svg" class="burger-icon" />Games
                </router-link>
              </li>
              <li>
                <router-link to="/video" onclick="closeNav()"
                  ><img src="../assets/home.svg" class="burger-icon" />Videos
                </router-link>
              </li>
              <li>
                <router-link to="/subscription-plan" onclick="closeNav()"
                  ><img
                    src="../assets/gold-coins-icon.svg"
                    class="burger-icon"
                  />Subscription plan
                </router-link>
              </li>
              <li>
                <router-link to="/faq" onclick="closeNav()"
                  ><img src="../assets/faq.svg" class="burger-icon" />FAQ
                </router-link>
              </li>
              <li>
                <router-link to="/terms-conditions" onclick="closeNav()"
                  ><img src="../assets/tc.svg" class="burger-icon" />Terms &
                  Condition
                </router-link>
              </li>
              <li>
                <router-link to="/privacy-policy" onclick="closeNav()"
                  ><img src="../assets/pp.svg" class="burger-icon" />Privacy
                  Policy
                </router-link>
              </li>
              <li>
                <router-link to="/contact-us" onclick="closeNav()"
                  ><img
                    src="../assets/contact.svg"
                    class="burger-icon"
                  />Contact
                </router-link>
              </li>
              <li v-if="phone" @click="logout" style="color: gold;">
                <img
                  src="../assets/unsubscribe.svg"
                  class="burger-icon"
                />Logout
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="right-menu-area">
        <ul>
          <li v-if="phone">
            <div class="notifications">
              <button type="button" @click="showModal('notification')">
                <img src="../assets/notificatons.svg" />
              </button>

              <div class="notification-text" v-if="count">
                <button
                  type="button"
                  @click="showModal('notification')"
                  href="JavaScript:void(0);"
                >
                  {{ count }}
                </button>
              </div>
            </div>
          </li>
          <li v-if="phone">
            <div class="gold-icons">
              <router-link to="/subscription-plan">
                <img src="../assets/gold-coins-icon.svg" />
              </router-link>
            </div>
          </li>
          <li v-if="phone">
            <div class="user-icons">
              <router-link to="/profile">
                <img src="../assets/user.svg" />
              </router-link>
            </div>
          </li>
          <li v-if="logButton && !phone">
            <div class="user-icons">
              <router-link to="/registration">
                <img src="../assets/log-in-icon.svg" />
              </router-link>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="modal-background" id="notification">
      <div class="modal-content new-notification-01">
        <div class="modal-kit">
          <img src="../assets/polygon.svg" />
        </div>
        <span class="close" @click="hideModal('notification')"></span>
        <h6>
          Notifications
        </h6>

        <div class="notification-content" v-if="notifications.length">
          <div
            class="notification-main new-nt-con"
            v-for="notification in notifications"
            :key="notification.id"
          >
            <div class="not-icon not-0001">
              <img src="../assets/notification-small.svg" />
            </div>
            <div class="noti-text">
              <a :href="notification.link" style="text-decoration: none;">
                <ul>
                  <li>{{ notification.msg }}</li>
                  <li class="nt-dt-01">{{ notification.publish_time }}</li>
                </ul>
              </a>
            </div>
            <div class="del-ico">
              <button
                type="button"
                @click="deleteNotification(notification.id)"
              >
                <img src="../assets/del-red.svg" />
              </button>
            </div>
          </div>
        </div>
        <div v-else>
          <h6>No new notification found</h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Header",
  data: () => {
    return {
      host: null,
      phone: localStorage.getItem("phone") ? localStorage.getItem("phone") : "",
      logButton: 1,
      count: 0,
      notifications: []
    };
  },
  async created() {
    this.host = this.$root.url();
    let stateName = ["Regristration", "ForgotPassword", "Otp", "NewPass"];
    let currentState = this.$route.name;
    let position = stateName.indexOf(currentState);
    if (position != -1) {
      this.logButton = 0;
    }
    await this.daily_quiz_check(this.phone);
    //alert('notification');
  },
  methods: {
    logout: function() {
      localStorage.clear();
      this.$router.push({ name: "Registration" });
    },
    async daily_quiz_check(phone = null) {
      try {
        const path = "dailyQuizChecker";
        let url = "";
        if (phone) {
          url = `${this.host}/${path}?phone=${phone}`;
        } else {
          this.$root.mainMenuHide = false;
          return true;
        }
        let response = await axios.get(url);
        let success = response.data.success;
        if (success) {
          if (!response.data.quizStatus) {
            console.log("quiz off"); // when quiz start on next line
            // return this.$router.push({ name: "Quiz" });
          }
          this.notifications = response.data.notification;
          this.count = response.data.length;
          this.$root.mainMenuHide = false;
        } else {
          this.$root.mainMenuHide = false;
          if (response.data.code === 3004) {
            localStorage.clear();
            this.$router.push({ name: "Registration" });
          }
        }
      } catch (e) {
        console.log(e);
        this.$root.mainMenuHide = false;
      }
    },
    async showModal(id) {
      $("#" + id).fadeIn(200);
      $("body").toggleClass("no-scroll");
      await this.notification_seen();
    },
    hideModal(id) {
      $("#" + id).fadeOut(400);
      $("body").toggleClass("no-scroll");
    },
    async notification_seen() {
      try {
        const path = "notification_seen";
        let url = `${this.host}/${path}?phone=${this.phone}`;
        let response = await axios.get(url);
        if (response.data.success) {
          this.count = response.data.length;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async deleteNotification(id = 0) {
      try {
        const path = "notification_delete";
        let url = `${this.host}/${path}?notificationId=${id}&phone=${this.phone}`;
        let response = await axios.get(url);
        if (response.data.success) {
          this.notifications = response.data.notification;
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>

<style scoped>
.burger-icon {
  width: 18px;
  height: 18px;
  padding-right: 10px;
  -webkit-filter: invert(100%);
}
</style>
