import Vue from "vue";
import VueRouter from "vue-router";
import Footer from "../views/Footer.vue";
import Header from "../views/Header.vue";
import BottomNav from "../views/BottomNav.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/robi-promotion-one",
    components: {
      default: () => import("../views/RobiPromotion1.vue")
    },
    meta: {
      title: "Xoshplay | Robi Campaign"
    }
  },
  {
    path: "/robi-promotion-two",
    components: {
      default: () => import("../views/RobiPromotion2.vue")
    },
    meta: {
      title: "Xoshplay | Robi Campaign"
    }
  },
  {
    path: "/robi-promotion-three",
    components: {
      default: () => import("../views/RobiPromotion3.vue")
    },
    meta: {
      title: "Xoshplay | Robi Campaign"
    }
  },
  {
    path: "/robi-promotion-four",
    components: {
      default: () => import("../views/RobiPromotion4.vue")
    },
    meta: {
      title: "Xoshplay | Robi Campaign"
    }
  },
  {
    path: "/robi-promotion-five",
    components: {
      default: () => import("../views/RobiPromotion5.vue")
    },
    meta: {
      title: "Xoshplay | Robi Campaign"
    }
  },

  {
    path: "/",
    name: "Home",
    components: {
      default: () => import(/* webpackChunkName: "Home" */ "../views/Home.vue"),
      header: Header,
      footer: Footer,
      bottomNav: BottomNav
    },
    meta: {
      title: "Bangladeshi android mobile gaming app store | Xoshplay"
    }
  },
  {
    path: "/slider",
    name: "SliderMode",
    components: {
      default: () =>
        import(/* webpackChunkName: "SliderMode" */ "../views/SliderMode.vue"),
      header: Header,
      footer: Footer,
      bottomNav: BottomNav
    },
    meta: {
      title: "Xoshplay - SliderMode"
    }
  },
  {
    path: "/registration",
    name: "Registration",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "Regristration" */
          "../views/Regristration.vue"
        ),
      header: Header,
      footer: Footer,
      bottomNav: BottomNav
    },
    meta: {
      title: "Xoshplay - Registration"
    }
  },
  {
    path: "/subscription-plan",
    name: "SubscriptionPlan",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "SubscriptionPlan" */
          "../views/SubscriptionPlan.vue"
        ),
      header: Header,
      footer: Footer,
      bottomNav: BottomNav
    },
    meta: {
      title: "Xoshplay - SubscriptionPlan"
    }
  },
  {
    path: "/game-detail/:gameId/play",
    name: "GamePlay",
    components: {
      default: () =>
        import(/* webpackChunkName: "GamePlay" */ "../views/GamePlay.vue")
    },
    meta: {
      title: "Xoshplay - Game Play"
    }
  },
  {
    path: "/shop",
    name: "Shop",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "GameDetail" */
          "../views/UnderConstruction.vue"
        ),
      header: Header,
      footer: Footer,
      bottomNav: BottomNav
    },
    meta: {
      title: "Xoshplay - Shop"
    }
  },
  {
    path: "/video",
    name: "Video",
    components: {
      default: () =>
        import(/* webpackChunkName: "Video" */ "../views/Video.vue"),
      header: Header,
      footer: Footer,
      bottomNav: BottomNav
    },
    meta: {
      title: "Xoshplay | Video"
    }
  },
  {
    path: "/video-play/:videoId/play",
    name: "VideoPlay",
    components: {
      default: () =>
        import(/* webpackChunkName: "VideoPlay" */ "../views/VideoPlay.vue"),
      header: Header,
      footer: Footer,
      bottomNav: BottomNav
    },
    meta: {
      title: "Xoshplay - Video Play"
    }
  },
  {
    path: "/streamer/:id/details",
    name: "Streamer",
    components: {
      default: () =>
        import(/* webpackChunkName: "Streamer" */ "../views/Streamer.vue"),
      header: Header,
      footer: Footer,
      bottomNav: BottomNav
    },
    meta: {
      title: "Xoshplay | Streamer"
    }
  },
  {
    path: "/under-construction",
    name: "UnderConstruction",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "GameDetail" */
          "../views/UnderConstruction.vue"
        ),
      header: Header,
      footer: Footer,
      bottomNav: BottomNav
    },
    meta: {
      title: "Xoshplay - UnderConstruction"
    }
  },
  {
    path: "/notification",
    name: "Notification",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "GameDetail" */
          "../views/Notification.vue"
        ),
      header: Header,
      footer: Footer,
      bottomNav: BottomNav
    },
    meta: {
      title: "Xoshplay - Notification"
    }
  },
  {
    path: "/checkout",
    name: "Checkout",
    components: {
      default: () =>
        import(/* webpackChunkName: "GameDetail" */ "../views/Checkout.vue"),
      header: Header,
      footer: Footer,
      bottomNav: BottomNav
    },
    meta: {
      title: "Xoshplay - Checkout"
    }
  },
  {
    path: "/profile",
    name: "Profile",
    components: {
      default: () =>
        import(/* webpackChunkName: "GameDetail" */ "../views/Profile.vue"),
      header: Header,
      footer: Footer,
      bottomNav: BottomNav
    },
    meta: {
      title: "Xosh - Profile"
    }
  },
  {
    path: "/quiz",
    name: "Quiz",
    components: {
      default: () => import(/* webpackChunkName: "Quiz" */ "../views/Quiz.vue")
      // header: Header,
      // footer: Footer,
      // bottomNav: BottomNav
    },
    meta: {
      title: "Xoshplay - Quiz"
    }
  },
  {
    path: "/faq",
    name: "Faq",
    components: {
      default: () => import(/* webpackChunkName: "Faq" */ "../views/Faq.vue"),
      header: Header,
      bottomNav: BottomNav
    },
    meta: {
      title: "FAQ | Xoshplay"
    }
  },
  {
    path: "/contact-us",
    name: "Contact",
    components: {
      default: () =>
        import(/* webpackChunkName: "Contact" */ "../views/Contact.vue"),
      header: Header,
      bottomNav: BottomNav
    },
    meta: {
      title: "Contact | Xoshplay"
    }
  },
  {
    path: "/terms-conditions",
    name: "TermCondition",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "TermCondition" */
          "../views/TermCondition.vue"
        ),
      header: Header,
      bottomNav: BottomNav
    },
    meta: {
      title: "TermCondition | Xoshplay"
    }
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "PrivacyPolicy" */
          "../views/PrivacyPolicy.vue"
        ),
      header: Header,
      bottomNav: BottomNav
    },
    meta: {
      title: "PrivacyPolicy | Xoshplay"
    }
  },
  {
    path: "/teletalk-campaign",
    name: "TtCp",
    components: {
      default: () =>
        import(/* webpackChunkName: "Campaign" */ "../views/Campaign.vue")
    },
    meta: {
      title: "Xoshplay | Campaign"
    }
  },
  {
    path: "/robi-campaign",
    name: "RoCp",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "RobiCampaign" */
          "../views/RobiCampaign.vue"
        )
    },
    meta: {
      title: "Xoshplay | Robi Campaign"
    }
  },
  {
    path: "/community",
    name: "Community",
    components: {
      default: () =>
        import(/* webpackChunkName: "Community" */ "../views/Community.vue"),
      header: Header,
      footer: Footer,
      bottomNav: BottomNav
    },
    meta: {
      title: "Xoshplay | Community"
    }
  },
  {
    path: "/success",
    name: "Success",
    components: {
      default: () =>
        import(/* webpackChunkName: "Success" */ "../views/Success.vue")
    },
    meta: {
      title: "Success | Xoshplay"
    }
  },
  {
    path: "/unsub",
    name: "Unsubscribe",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "Unsubscribe" */
          "../views/Unsubscribe.vue"
        )
    },
    meta: {
      title: "Unsubscribe | Xoshplay"
    }
  },
  {
    path: "/fyftournament.html",
    name: "Tournament",
    components: {
      default: () =>
        import(/* webpackChunkName: "Unsubscribe" */ "../views/Tournament.vue")
    },
    meta: {
      title: "TOURNAMENT | Xoshplay"
    }
  },
  {
    path: "*",
    redirect: "/"
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
