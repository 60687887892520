<template>
  <div>
    <div class="content-header">
      <div class="title-1 new-01">Spotlights</div>
      <div class="user-icons newicons">
        <router-link to="/slider">
          <img src="../assets/switch-button.svg" class="slider-tap" />
        </router-link>
      </div>
    </div>

    <div class="main-games-top" v-if="smallImage">
      <ul>
        <li v-for="spotLightGame in spotLightGames" :key="spotLightGame.app_id">
          <router-link
            :to="{
              name: 'GamePlay',
              params: { gameId: spotLightGame.app_id }
            }"
            class="wid-1"
          >
            <div class="trp">
              <!--              <div class="new-entry"><p>{{spotLightGame.is_paid_game ? 'Paid':'Free'}}</p></div>-->
              <div class="new-entry" v-if="spotLightGame.is_new">
                <p>New</p>
              </div>
              <br />
              <!--              <div class="new-entry" style="background: #d82727"><p>Exclusive</p></div>-->
            </div>
            <div class="exclusive" v-if="spotLightGame.is_paid_game">
              <p>Exclusive</p>
            </div>
            <div class="exclusive" v-else>
              <p>Free</p>
            </div>
            <!--            <img :src="host + spotLightGame.thumbnail_path" />-->
            <img :src="pathName(spotLightGame.thumbnail_path)" />
            <span>{{ spotLightGame.title }}</span>
            <div class="cat-1">
              {{ spotLightGame.long_description.substr(0, 20) }}...
            </div>
            <div class="rating-box">
              <div
                class="rating"
                :style="{
                  width: spotLightGame.rating
                    ? spotLightGame.rating + '%'
                    : '100%'
                }"
              ></div>
            </div>
          </router-link>
        </li>
      </ul>
    </div>

    <div class="content-slider" v-if="!smallImage">
      <div
        class="slider-01"
        v-for="spotLightGame in spotLightGames"
        :key="spotLightGame.app_id"
      >
        <div class="slider-main-cont">
          <div class="pl-coin">
            <ul>
              <li><img src="../assets/gold-coins-icon.svg" /></li>
              <li>{{ spotLightGame.charge_coin }}</li>
            </ul>
          </div>
          <div class="titles inner-slider-pg">{{ spotLightGame.title }}</div>

          <div class="slider-img">
            <router-link
              :to="{
                name: 'GamePlay',
                params: { gameId: spotLightGame.app_id }
              }"
            >
              <!--              <img :src="host + spotLightGame.thumbnail_path" />-->
              <img :src="pathName(spotLightGame.thumbnail_path)" />
            </router-link>
          </div>
          <div class="cat-description-slider">
            {{ spotLightGame.long_description.substr(0, 20) }}...
          </div>
          <p>{{ spotLightGame.avgRating ? spotLightGame.avgRating : "5.0" }}</p>
          <div class="rating-box">
            <div
              class="rating"
              :style="{
                width: spotLightGame.rating
                  ? spotLightGame.rating + '%'
                  : '100%'
              }"
            ></div>
          </div>
          <div class="subtitles">
            {{ spotLightGame.count }}
            {{ spotLightGame.count == 1 ? "Player" : "Players" }}
          </div>

          <div class="redeem-gift">
            <router-link
              :to="{
                name: 'GamePlay',
                params: { gameId: spotLightGame.app_id }
              }"
            >
              Start Game
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="top-category">
      <div class="title-section"></div>

      <div class="cat-btn">
        <div
          class="cat-nm-1"
          v-bind:class="{ active: activeCategory === 'all' }"
        >
          <a @click="gameCategoryList('all')">
            <img src="../assets/arcade.svg" /> All
          </a>
        </div>
        <div
          class="cat-nm-1"
          v-for="category in gameCategory"
          :key="category"
          v-bind:class="{ active: activeCategory === category }"
        >
          <a @click="gameCategoryList(category)">
            <img src="../assets/tournaments.svg" />{{ category }}
          </a>
        </div>
      </div>
    </div>
    <div class="single-games">
      <!--      <div class="main-games-top">-->
      <ul class="games-box">
        <li v-for="game in gameList" :key="game.app_id">
          <router-link
            :to="{ name: 'GamePlay', params: { gameId: game.app_id } }"
            class="wid-1"
          >
            <div class="trp">
              <div class="new-entry" v-if="game.is_new"><p>New</p></div>
              <br />
            </div>
            <div class="exclusive" v-if="game.is_paid_game">
              <p>Exclusive</p>
            </div>
            <div class="exclusive" v-else>
              <p>Free</p>
            </div>
            <!--            <img :src="host + game.thumbnail_path" />-->
            <img :src="pathName(game.thumbnail_path)" />
            <span> {{ game.title }}</span>
            <div class="cat-1">
              {{ game.long_description.substr(0, 20) }}...
            </div>
          </router-link>
        </li>
      </ul>
    </div>
    <scroll-loader :loader-method="scrollTo()" :loader-disable="disable">
      <div>Loading...</div>
    </scroll-loader>
    <div class="modal-background" id="regModal2">
      <div class="modal-content pop-02">
        <span class="close" onclick="hideModal('regModal2')"></span>
        <h4>Download the Xoshplay app now.</h4>
        <div
          @click="redirectPlayStore('regModal2')"
          class="submit-btn5"
          style="padding-top: 35px;"
        >
          Go To App
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ScrollLoader from "vue-scroll-loader";
export default {
  name: "Game",
  components: {
    "scroll-loader": ScrollLoader
  },
  data: () => {
    return {
      gameList: [],
      spotLightGames: [],
      gameCategory: [],
      searchData: [],
      isLogin: 0,
      host: null,
      loadMore: true,
      activeCategory: "all",
      page: 1,
      pageSize: 15,
      smallImage: true,
      starTotal: 5,
      webView: null,
      msisdn: localStorage.getItem("phone")
        ? localStorage.getItem("phone")
        : "empty",
      appPopUp: localStorage.getItem("appPopUp")
        ? localStorage.getItem("appPopUp")
        : "0",
      disable: false
    };
  },
  created() {
    this.host = this.$root.url();
    const url = this.host;
    axios
      .get(url)
      .then(response => {
        this.gameList = response.data.allGames;
        this.searchData = this.gameList;
        this.spotLightGames = response.data.spotLightGames;
        this.gameCategory = response.data.gameCategory;
        this.isLogin = response.data.isLogin;
        this.webView = response.data.view;
        if (this.appPopUp === "0" && this.webView === "Browser") {
          window.showModal("regModal2");
          localStorage.setItem("appPopUp", "1");
        }
      })
      .catch(error => {
        console.log(error);
      });
  },
  // mounted() {
  //   this.scrollTo();
  // },
  methods: {
    scrollTo() {
      let vm = this;
      $(window).scroll(async function() {
        if (
          $(window).scrollTop() === $(document).height() - $(window).height() &&
          !vm.disable
        ) {
          //  alert("scroll");
          try {
            vm.host = vm.$root.url();
            const path = "loadMoreContent";
            const url = `${vm.host}/${path}?offset=${vm.gameList.length}&limit=15`;
            let response = await axios.get(url);
            let { allGames } = response.data;
            vm.disable = !allGames.length;
            vm.gameList = vm.gameList.concat(allGames);
          } catch (error) {
            console.log(error);
          }
        }
      });
    },
    redirectPlayStore(id) {
      window.hideModal(id);
      window.open(
        "https://play.google.com/store/apps/details?id=com.xoshplay.store"
      );
    },
    shortDescription: function(str) {
      let result = str;
      let resultArray = result.split(" ");
      if (resultArray.length > 12) {
        resultArray = resultArray.slice(0, 12);
        result = resultArray.join(" ") + "…";
      }
      return result;
    },
    getGameList: function() {
      alert("scrolling");
    },
    imageStatus(msisdn = "empty") {
      console.log(msisdn);
      this.$router.push({ name: "SliderMode" });
    },
    gameCategoryList(category) {
      this.activeCategory = category;
      let findData = [];
      if (category !== "all") {
        findData = this.searchData.filter(
          game => game.catagory_name === category
        );
      } else {
        findData = this.searchData;
      }
      this.gameList = findData;
    },
    pathName(url) {
      let newUrl = "";
      //console.log("url ", url);
      let substring = url.slice(0, 4);
      if (substring === "http") {
        newUrl = url;
      } else {
        newUrl = this.host + url;
      }
      return newUrl;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.rating-box {
  color: #f68127;
}

.rating-box:before {
  content: "\f006 \f006 \f006 \f006 \f006";
}

.rating-box .rating {
  color: #f68127;
}

.rating-box .rating:before {
  content: "\f005 \f005 \f005 \f005 \f005";
}
</style>
